import React from "react";
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import * as BiIcons from "react-icons/bi";
import * as VscIcons from "react-icons/vsc";
import * as TiIcons from "react-icons/ti";
import * as GiIcons from "react-icons/gi";
import * as BsIcons from "react-icons/bs";
import * as TbIcons from "react-icons/tb";

import { IoBandageOutline } from "react-icons/io5";
import { BsCurrencyDollar } from "react-icons/bs";
import { BsTrophy } from "react-icons/bs";
import { GiStrong } from "react-icons/gi";

export const SidebarData = [
  {
    title: "Premiações",
    path: "/premiacoes",
    icon: <BsTrophy />,
    iconClosed: <RiIcons.RiArrowDropDownFill />,
    iconOpened: <RiIcons.RiArrowDropUpFill />,
    cName: "nav-text",
    subNav: [
      {
        title: "Alta Performance",
        path: "/premiacoes/performance",
        icon: <BsCurrencyDollar />,
        cName: "nav-text2",
      },
      {
        title: "Performance por Carteira",
        path: "/premiacoes/acompanhamento",
        icon: <GiStrong />,
        cName: "nav-text2",
      },
    ],
  },
  {
    title: "META BASE",
    path: "/metabase",
    icon: <VscIcons.VscGraph />,
    iconClosed: <RiIcons.RiArrowDropDownFill />,
    iconOpened: <RiIcons.RiArrowDropUpFill />,
    cName: "nav-text",
    subNav: [
      {
        title: "Cooperados",
        path: "/metabase/cooperados",
        icon: <BiIcons.BiUser />,
        cName: "nav-text2",
      },
      {
        title: "Recuperacao de Crédito",
        path: "/metabase/recuperacao",
        icon: <IoBandageOutline />,
        cName: "nav-text2",
      },
      {
        title: "Deposito à prazo",
        path: "/metabase/prazo",
        icon: <FaIcons.FaRegMoneyBillAlt />,
        cName: "nav-text2",
      },
      {
        title: "Deposito à vista",
        path: "/metabase/vista",
        icon: <FaIcons.FaMoneyBillAlt />,
        cName: "nav-text2",
      },
      /*{
        title: "Sobras",
        path: "/metabase/vista",
        icon: <FaPiggyBank />,
        cName: "nav-text2",
      },*/
    ],
  },
  {
    title: "PRODUTOS",
    path: "/produtos",
    icon: <VscIcons.VscGraphLine />,
    iconClosed: <RiIcons.RiArrowDropDownFill />,
    iconOpened: <RiIcons.RiArrowDropUpFill />,
    cName: "nav-text",
    subNav: [
      {
        title: "Canais",
        path: "/produtos/canais",
        icon: <RiIcons.RiCellphoneFill />,
        cName: "nav-text2",
      },
      {
        title: "Cartões Visa",
        path: "/produtos/visa",
        icon: <RiIcons.RiVisaLine />,
        cName: "nav-text2",
      },
      {
        title: "Cobrança",
        path: "/produtos/cobranca",
        icon: <TiIcons.TiDocument />,
        cName: "nav-text2",
      },
      {
        title: "Domicilio Bancário",
        path: "/produtos/domicilio",
        icon: <BsIcons.BsCreditCard2Back />,
        cName: "nav-text2",
      },
      {
        title: "Seguro Auto Elementar",
        path: "/produtos/AutoElementar",
        icon: <BiIcons.BiBuildings />,
        cName: "nav-text2",
      },
      {
        title: "Seguro Vida",
        path: "/produtos/vida",
        icon: <GiIcons.GiHeartShield />,
        cName: "nav-text2",
      },
      {
        title: "Título de Capitalização",
        path: "/produtos/capitalizacao",
        icon: <BiIcons.BiCoinStack />,
        cName: "nav-text2",
      },
      {
        title: "Previdência",
        path: "/produtos/previdencia",
        icon: <TbIcons.TbOld />,
        cName: "nav-text2",
      },
    ],
  },
  {
    title: "Bônus",
    path: "/Bonus",
    icon: <GiIcons.GiStarMedal />,
    iconClosed: <RiIcons.RiArrowDropDownFill />,
    iconOpened: <RiIcons.RiArrowDropUpFill />,
    cName: "nav-text",
    subNav: [
      {
        title: "Pendência Zero",
        path: "/bonus/pendencia",
        icon: <FaIcons.FaBalanceScale />,
        cName: "nav-text2",
      },
    ],
  },
  /*{
    title: "Documentos",
    path: "/Normativos",
    icon: <BiIcons.BiArchive />,
    iconClosed: <BiIcons.BiArchive />,
    iconOpened: <BiIcons.BiArchive />,
    cName: "nav-text",
    subNav: [
      {
        title: "Documentos",
        path: "/normativos/normativos",
        icon: <BiIcons.BiFile />,
        cName: "nav-text2",
      },
    ],
  },*/
];
