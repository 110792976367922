import React from "react";

function Cobranca() {
  return (
    <div className="home">
      <iframe
        title="Cobrança"
        class="video"
        widht="100%"
        height="100%"
        src="https://app.powerbi.com/reportEmbed?reportId=938aa154-6981-41dd-af58-1b905e222da2&autoAuth=true&ctid=f88c7305-11d1-4b34-a8a0-da6536019e31"
        marginheight="0"
        marginwidth="0"
        scrolling="0"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
}

export default Cobranca;
