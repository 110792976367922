import React from "react";
import { Desabilitar } from "../../components/Mouse";

function Cooperados() {
  return (
    <>
      <Desabilitar />
      <div className="home">
        <iframe
          title="Cooperados"
          class="video"
          widht="100%"
          height="100%"
          src="https://app.powerbi.com/reportEmbed?reportId=0ffa1885-b76b-478c-8c69-e10e1a2c6cd4&autoAuth=true&ctid=f88c7305-11d1-4b34-a8a0-da6536019e31"
          marginheight="0"
          marginwidth="0"
          scrolling="0"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    </>
  );
}

export default Cooperados;
