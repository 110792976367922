import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";
import { SidebarData } from "../components/SidebarData";
import "./Navbar.css";
import { IconContext } from "react-icons";
import Logo from "../components/logounicred.png";
import Menubars from "../components/menu-bars.png";
import * as CgIcons from "react-icons/cg";

function Navbar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: "#edebeb" }}>
        <div className="navbar">
          <Link to="#" className="menu-bars">
            <CgIcons.CgMenu onClick={showSidebar} />
          </Link>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to="/" className="menu-bars">
                <div className="imagemlogo">
                  <img src={Logo} alt="logo unicred" />
                </div>
              </Link>
            </li>
            <br></br>

            {SidebarData.map((item, index) => {
              return (
                <>
                  {" "}
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                  {item.subNav.map((subNav) => (
                    <ul>
                      <li key={subNav.index} className={subNav.cName}>
                        <Link to={subNav.path}>
                          {subNav.icon} {subNav.title}
                        </Link>
                      </li>
                    </ul>
                  ))}
                </>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
