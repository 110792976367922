import React from "react";
//import { Desabilitar } from "../../components/Mouse";

function Acompanhamento() {
  return (
    <>
      
      <div className="home">
        <iframe
          title="Performance"
          class="video"
          widht="100%"
          height="100%"
          src="https://app.powerbi.com/reportEmbed?reportId=df6be3e8-92ea-463b-abd0-da655d6d7ccd&autoAuth=true&ctid=f88c7305-11d1-4b34-a8a0-da6536019e31"
          marginheight="0"
          marginwidth="0"
          scrolling="0"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    </>
  );
}

export default Acompanhamento;
