import React from "react";
import Logo2 from "../components/edata.png";

function Home() {
  return (
    <div className="home2">
      <img className="img2" src={Logo2} />
      <footer>Versão 4.0</footer>
    </div>
  );
}

export default Home;
