import React from "react";

function Vista() {
  return (
    <div className="home">
      <iframe
        title="Vista"
        class="video"
        widht="100%"
        height="100%"
        src=" https://app.powerbi.com/reportEmbed?reportId=e66cfa80-dc08-422e-8e1b-1befd80b6b39&autoAuth=true&ctid=f88c7305-11d1-4b34-a8a0-da6536019e31"
        marginheight="0"
        marginwidth="0"
        scrolling="0"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
}

export default Vista;
