import React from "react";

const Prazo = () => {
  return (
    <div className="home">
      <iframe
        title="Prazo"
        class="video"
        widht="100%"
        height="100%"
        src="https://app.powerbi.com/reportEmbed?reportId=401e3995-3a1c-4ea1-9c4a-4d1c6450daa5&autoAuth=true&ctid=f88c7305-11d1-4b34-a8a0-da6536019e31"
        marginheight="0"
        marginwidth="0"
        scrolling="0"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};

export default Prazo;
