import React from "react";

function AutoElementar() {
  return (
    <div className="home">
      <iframe
        title="Elementar"
        class="video"
        widht="100%"
        height="100%"
        src="https://app.powerbi.com/reportEmbed?reportId=01ad0d68-f7f5-4a75-815d-15ad066e2944&autoAuth=true&ctid=f88c7305-11d1-4b34-a8a0-da6536019e31"
        marginheight="0"
        marginwidth="0"
        scrolling="0"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
}

export default AutoElementar;
