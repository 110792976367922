import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import Cooperados from "./pages/metabase/Cooperados";
import Home from "./pages/Home";
import Prazo from "./pages/metabase/Prazo";
import Vista from "./pages/metabase/Vista";
import Vida from "./pages/produtos/Vida";
import Capitalizacao from "./pages/produtos/Capitalizacao";
import Pendencia from "./pages/bonus/Pendencia";
import Cobranca from "./pages/produtos/Cobranca";
import Domicilio from "./pages/produtos/Domicilio";
import Visa from "./pages/produtos/Visa";
import Previdencia from "./pages/produtos/Previdencia";
import AutoElementar from "./pages/produtos/AutoElementar";
import { Desabilitar } from "./components/Mouse";
import Canais from "./pages/produtos/Canais";
import Recuperacao from "./pages/metabase/Recuperacao";
import Normativos from "./pages/normativos/Normativos";
import Performance from "./pages/premiacoes/performance";
import Acompanhamento from "./pages/premiacoes/Acompanhamento";
/*import Sobras from "./pages/metabase/Sobras";*/

function App() {
  return (
    <>
      <Router>
        <Desabilitar />
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/metabase/" element={<Home />} />
          <Route exact path="/produtos/" element={<Home />} />
          <Route exact path="/bonus/" element={<Home />} />
          <Route exact path="/premiacoes/" element={<Home />} />
          <Route exact path="/metabase/cooperados" element={<Cooperados />} />
          <Route exact path="/metabase/recuperacao" element={<Recuperacao />} />
          <Route exact path="/metabase/prazo" element={<Prazo />} />
          <Route exact path="/metabase/vista" element={<Vista />} />
          {/* <Route exact path="/metabase/sobras" element={<Sobras />} /> */}
          <Route exact path="/produtos/vida" element={<Vida />} />
          <Route exact path="/produtos/domicilio" element={<Domicilio />} />
          <Route exact path="/produtos/AutoElementar" element={<AutoElementar />} />
          <Route exact path="/produtos/vida" element={<Vida />} />
          <Route
            exact
            path="/produtos/capitalizacao"
            element={<Capitalizacao />}
          />
          <Route exact path="/produtos/cobranca" element={<Cobranca />} />
          <Route exact path="/produtos/visa" element={<Visa />} />
          <Route exact path="/produtos/canais" element={<Canais />} />
          <Route exact path="/produtos/previdencia" element={<Previdencia />} />
          <Route exact path="/bonus/pendencia" element={<Pendencia />} />
          <Route exact path="/normativos/normativos" element={<Normativos />} />
          <Route exact path="premiacoes/performance" element={<Performance />} />
          <Route exact path="premiacoes/acompanhamento" element={<Acompanhamento />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
