import React from "react";
import { Desabilitar } from "../../components/Mouse";

function Recuperacao() {
  return (
    <>
      <Desabilitar />
      <div className="home">
        <iframe
          title="Recuperacao"
          class="video"
          widht="100%"
          height="100%"
          src="https://app.powerbi.com/reportEmbed?reportId=0319a4b5-ea7e-4aca-8521-8b507701c68b&autoAuth=true&ctid=f88c7305-11d1-4b34-a8a0-da6536019e31"
          marginheight="0"
          marginwidth="0"
          scrolling="0"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    </>
  );
}

export default Recuperacao;
