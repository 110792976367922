import React from "react";

function Previdencia() {
  return (
    <div className="home">
      <iframe
        title="Domicilio"
        class="video"
        widht="100%"
        height="100%"
        src="https://app.powerbi.com/reportEmbed?reportId=aecc84d3-82f7-453f-9300-8dd1f4dcb9d1&autoAuth=true&ctid=f88c7305-11d1-4b34-a8a0-da6536019e31"
        marginheight="0"
        marginwidth="0"
        scrolling="0"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
}

export default Previdencia;
