import React from "react";
import { Desabilitar } from "../../components/Mouse";
import { AiFillFileExcel } from "react-icons/ai";
import { AiFillFileWord } from "react-icons/ai";
import { AiFillFilePdf } from "react-icons/ai";

function Normativos() {
  return (
    <>
      <Desabilitar />
      <div className="container">
        <table>
          <caption>
            <div className="tabela">
              <br /> <br /> <br />
              <h2>Biblioteca de Documentos Unicred Evolução</h2>
              <br />
            </div>
          </caption>
          <br></br>
          <thead>
            <tr>
              <th></th>
              <th>
                <h2>Documentos</h2>
              </th>
              <th></th>
            </tr>
          </thead>
          <tr>
            <td>Apresentação Novo Módulo Controla</td>
            <td>Cartilha Fraude de Cheques</td>
            <td>Questionário de Práticas Sócio Ambientais</td>
          </tr>
          <tr>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Apresenta%C3%A7%C3%A3o%20Novo%20m%C3%B3dulo%20Controla%20COAF.pdf"
                  target="_blank"
                >
                  <AiFillFilePdf className="docIcon" />
                </a>
              </div>
            </td>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Cartilha%20Fraudes%20de%20Cheques.pdf"
                  target="_blank"
                >
                  <AiFillFilePdf className="docIcon" />
                </a>
              </div>
            </td>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Question%C3%A1rio%20de%20Pr%C3%A1ticas%20Socioambientais%20-%20PDF.pdf"
                  target="_blank"
                >
                  <AiFillFilePdf className="docIcon" />
                </a>
              </div>
            </td>
          </tr>
          <br /> <br /> <br />
          <tr>
            <td>Questionário de Práticas Sócio Ambientais em Excel</td>
            <td>Saque Avulso de Colaboradores</td>
            <td>Autorização Compartilhamento de Dados </td>
          </tr>
          <tr>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Question%C3%A1rio%20de%20Pr%C3%A1ticas%20Socioambientais.xlsx"
                  target="_blank"
                >
                  <AiFillFileExcel className="docIcon" />
                </a>
              </div>
            </td>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Saque%20Avulso%20Colaboradores.pdf"
                  target="_blank"
                >
                  <AiFillFilePdf className="docIcon" />
                </a>
              </div>
            </td>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Autorizacao%20para%20compartilhamento%20de%20dados.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
          </tr>
          <br /> <br /> <br />
          <tr>
            <td>Autorização Para Entrega Cheque Devolvido</td>
            <td>Inventário Talões de Cheque</td>
            <td>Termo Chave e Alarme Agência</td>
          </tr>
          <tr>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Autorizacao%20para%20entreda%20de%20cheque%20devolvido.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Inventario%20taloes%20de%20cheque.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Termo%20Chave%20e%20Alarme%20agencia.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
          </tr>
          <br /> <br /> <br />
          <tr>
            <td>Termo Chave e Alarme Unidade Administrativa</td>
            <td>Termo Conferencia de Custódia</td>
            <td>Termo de Autorização Estorno Depósito Cheque Mobile</td>
          </tr>
          <tr>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Termo%20Chave%20e%20Alarme%20agencia.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Termo%20Conferencia%20de%20custodia.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Termo%20de%20autorizacao%20estorno%20deposito%20cheque%20mobile.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
          </tr>
          <br /> <br /> <br />
          <tr>
            <td>Termo Conferência de Tesouraria</td>
            <td>Termo de Incineração Cartões de Crédito e Débito </td>
            <td>
              Termo de Incineração Folhas Cheques Entregues pelo Cooperados
            </td>
          </tr>
          <br /> <br /> <br />
          <tr>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Termo%20de%20Conferencia%20de%20tesouraria.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Termo%20de%20incineracao%20cartoes%20de%20credito%20e%20debito.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Termo%20de%20incineracao%20de%20folhas%20de%20cheque%20entregue%20pelo%20cooperado.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
          </tr>
          <br /> <br /> <br />
          <tr>
            <td>
              Termo de Incineração Folhas Cheques não Entregues pelo Cooperados
            </td>
            <td>Termo de Regularização CCF</td>
            <td>Termo Responsabilidade Chave e Segredo do Cofre</td>
          </tr>
          <tr>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Termo%20de%20incineracao%20de%20folhas%20de%20cheque%20nao%20entregue%20pelo%20cooperado.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Termo%20de%20regularizacao%20CCF.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Termo%20de%20regularizacao%20CCF.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
          </tr>
          <br /> <br /> <br />
          <tr>
            <td>Termo Estorno Lançamento em conta Corrente</td>
            <td>Termo Incineração de Cheques </td>
            <td></td>
          </tr>
          <tr>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Termo%20Estorno%20lancaamento%20em%20conta%20corrente.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
            <td>
              <div className="imagempdf">
                <a
                  href="http://10.62.63.29:3333/files/Termo%20Incineracao%20de%20Cheques.docx"
                  target="_blank"
                >
                  <AiFillFileWord className="docIcon" />
                </a>
              </div>
            </td>
          </tr>
          <br />
          <br />
          <br />
        </table>
      </div>
    </>
  );
}

export default Normativos;
